<template>
  <div>
    <b-row>
      <b-col cols="12" lg="12">
        <ek-table
          :items="teachersDetails"
          :columns="DetailteacherCols"
          no_delete
          @details="goToDetailsInvoice"
        >
          <template slot="items.date" slot-scope="{ value }">
            {{ value ? new Date(value).toLocaleDateString() : "" }}
          </template>
        </ek-table>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  props: {
    id: String,
  },
  computed: {
    ...mapState({
      teachersDetails: (state) => state.invoiceTeacher.teachersDetails,
      DetailteacherCols: (state) => state.invoiceTeacher.DetailteacherCols,
    }),
    ...mapGetters(["teacherDetailsList"]),
  },
  data: () => ({}),
  methods: {
    ...mapActions(["getDetailsTeacherList"]),
    goToDetailsInvoice({ row }) {
      this.$router.push({
        path: `/admin/invoice/invoiceTeacher/${this.id}/${row.id}`,
      });
    },
  },
  created() {
    this.getDetailsTeacherList(this.id);
  },
};
</script>